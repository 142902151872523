header nav {
  --sec-1-3-min-width: 260px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 0;
}

/* Logo Size */
header nav .logo-img {
  width: auto;
  height: 44px;
}

/* ==> Sec-1 Properties */
header nav .sec-1 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-width: var(--sec-1-3-min-width);
}

/* ==> Sec-2 Properties */
header nav .sec-2 {
  flex-grow: 1;
  display: grid;
  place-items: center;
}

header nav .sec-2 .top-logo-close-col {
  display: none;
}
header nav .sec-2 .side-nav-sm-end {
  display: none;
}

header nav .sec-2 .nav-ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}
header nav .sec-2 .nav-ul .nav-link {
  display: inline-block;
  padding: 5px 0;
  color: var(--nav-link-color);
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
}
header nav .sec-2 .nav-ul .nav-link::after {
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--active-nav-link);
  transition: var(--Ltrns);
}

header nav .sec-2 .nav-ul .nav-link.active {
  color: var(--active-nav-link);
  font-weight: 500;
}
header nav .sec-2 .nav-ul .nav-link.active::after {
  width: 50%;
}

/* ==> Sec-3 Properties */
header nav .sec-3 {
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  min-width: var(--sec-1-3-min-width);
  transition: var(--Ltrns);
}
header nav .sec-3 button {
  text-wrap: nowrap;
}

/* Secrch Btn */
header nav .sec-3 .search-btn {
  width: 44px;
  display: grid;
  place-items: center;
  aspect-ratio: 1/1;
  border-radius: var(--bdr-prim);
  font-size: 18px;
  position: relative;
}
header nav .sec-3 .search-btn .dropdown-box {
  position: absolute;
  top: 100%;
  right: 0;
  width: 50vw;
  background-color: rgb(254, 254, 254);
  border-radius: 12px;
  padding: 27px 32px;
  z-index: +1;
  box-shadow: var(--shadow-A);
  cursor: auto;
  display: none;
}
header nav .sec-3 .search-btn .dropdown-box.show {
  display: block;
}

header nav .sec-3 .search-btn .dropdown-box .close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}
header nav .sec-3 .search-btn .dropdown-box .search-content-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
header nav .sec-3 .search-btn .dropdown-box .nav-link {
  display: flex;
  align-items: center;
  gap: 17px;
  padding: 15px;
  border: 0.5px solid #f0f0f0cf;
}

header nav .sec-3 .search-btn .dropdown-box .nav-link h5 {
  font-size: calc(var(--hd-mini-font-s) - 2);
  text-transform: capitalize;
  color: var(--HD-Dark);
}

/* Toggle Btn */
header nav .sec-3 .toggle-btn {
  width: 45px;
  display: none;
  place-items: center;
  border-radius: var(--bdr-prim);
  font-weight: 400;
  font-size: 22px;
  color: var(--txt-prime);
  border: 1px solid #34405421;
  background: #f8f7f7;
  transition: var(--Etrns);
}
header nav .sec-3 .toggle-btn:hover {
  color: #344054;
  border: 1px solid #344054;
}

/* %%%%%%%%%%%%%%%%%%%%%%%%%%%
  TOGGLE BREAK POINT (970px) 
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */
@media (max-width: 970px) {
  header nav {
    --sec-1-3-min-width: auto;
  }

  /* Show toggle btn */
  header nav .sec-3 .toggle-btn {
    display: grid;
  }

  header nav .sec-2 {
    display: block;
    position: fixed;
    top: 0;
    left: -100%;
    height: 100vh;
    width: 85vw;
    max-width: 317px;
    overflow-y: auto;
    padding: 0 15px 10px 15px;
    z-index: +99;
    background: #f8f7f7;
    box-shadow: var(--shadow-B);
    animation: hideAnimOut 2s ease;
  }
  @keyframes hideAnimOut {
    0% {
      left: 0;
    }

    100% {
      left: -100%;
    }
  }

  header nav .sec-2.active {
    left: 0;
    opacity: 1;
    animation: showAnimIn 1s ease;
  }
  @keyframes showAnimIn {
    0% {
      left: -100%;
    }

    100% {
      left: 0;
    }
  }

  header nav .sec-2 .top-logo-close-col {
    display: block;
    border-bottom: var(--border-prim);
    padding: 15px 0;
    margin-bottom: 30px;
  }

  header nav .sec-2 .top-logo-close-col .close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  header nav .sec-2 .nav-ul {
    flex-direction: column;
    gap: 0;
  }
  header nav .sec-2 .nav-ul .nav-link {
    display: block;
    padding: 17px 0;
    border-bottom: 1px solid rgba(203, 202, 202, 0.266);
    text-transform: uppercase;
  }
  header nav .sec-2 .nav-ul .nav-link.active {
    font-weight: 600;
  }
  header nav .sec-2 .nav-ul .nav-link::after {
    transform: translateX(0);
    left: 0;
    bottom: -1px;
  }
  header nav .sec-2 .nav-ul .nav-link.active::after {
    width: 70%;
  }
}

@media (max-width: 560px) {
  header nav .logo-img {
    height: 37px;
  }

  header nav .sec-2 .side-nav-sm-end {
    display: flex;
  }
  header nav .sec-2 .side-nav-sm-end .b-lg {
    display: flex;
    width: 100%;
    padding: 11px 10px;
    margin-top: 20px;
    justify-content: center;
  }

  header nav .sec-3 .prim-btn {
    display: none;
  }

  header nav .sec-3 .search-btn {
    width: 40px;
    font-size: 16px;
  }

  header nav .sec-3 .toggle-btn {
    width: 42px;
    font-size: 20px;
  }
}
