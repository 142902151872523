/*==> Footer Top Col */
footer .f-boxA {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 30px;
}

/* Clield A-1 */
.boxA1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 52px;
  flex-shrink: 0;
}

/* Clield A-2 */
.boxA2 {
  flex-shrink: 0;
}

.boxA2 .span-box {
  display: inline-block;
  color: #d8d8d8;
  font-size: 11px;
  padding: 5px 0;
}
.boxA2 .span-box2 {
  display: inline-block;
  color: #b4bbcb;
  font-size: 11px;
  padding-top: 6px;
}

/* Subscribe sec */
.subscribe-sec {
  display: flex;
  gap: 15px;
}

.subscribe-sec .form-i-box i {
  font-size: calc(var(--inputfield-font-size) - 2px);
}

/* Footer subscribe field */
footer .form-i-box {
  width: 345px;
}

/* Footer logo */
footer .f-boxA .foot-logo-img {
  width: auto;
  height: 40px;
}

/* Footer nav links */
footer .nav-ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 20px;
}
footer .nav-ul .nav-link {
  display: inline-block;
  padding: 5px 0;
  color: var(--nav-link-color);
  font-weight: 500;
  font-size: 13px;
}
footer .nav-ul .nav-link.active {
  color: var(--active-nav-link);
  font-weight: 500;
  position: relative;
}

/*==> Footer Botom Col */
footer .f-boxB {
  padding: 20px 0 90px 0;
  border-top: var(--border-prim);
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

footer .f-boxB p {
  color: var(--txt-gray);
}

@media (max-width: 790px) {
  /* Footer subscribe field */
  footer .form-i-box {
    width: 250px;
  }
}

@media (max-width: 710px) {
  footer .f-boxA {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  footer .boxA1,
  footer .boxA2 {
    width: 100%;
  }

  .boxA1 {
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  /* Footer subscribe field */
  footer .form-i-box {
    width: 100%;
  }
}

@media (max-width: 320px) {
  footer .f-boxB {
    flex-direction: column;
    align-items: center;
  }

  footer .f-boxB p {
    color: var(--txt-gray);
  }
}
