/* bg Colors */
.bg-red {
  background-color: red !important;
}

.bg-green {
  background-color: green !important;
}
.bg-drk {
  background-color: rgb(52, 52, 52) !important;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* font-family: 'Inter', sans-serif; */

:root {
  /* -- color  */
  --primary: #039855;
  --primary-hover: #04894d;
  --primary-lite: #d1fadf;
  --secoundry: #a6f4c5;
  --dim-color: #d9d9d9;

  /* Text Color */
  --HD-Dark: #101828;
  --HD-lite: #98a2b3;
  --txt-prime: #667085;
  --txt-gray: #98a2b3;

  /* Tag Colors */
  --tag-pink: #ffeeee;
  --tag-green: #f4fff8;
  --tag-purple: rgba(73, 94, 220, 0.08);
  --tag-cream: rgba(220, 214, 73, 0.08);

  /* Nav link color */
  --nav-link-color: var(--txt-prime);
  --active-nav-link: var(--HD-Dark);

  /* -- Backgrounds */
  --bg-prime: #d1fadf;
  --bg-prime-lite: #ecfdf3;
  --bg-lite: #f6f6f6;

  /* --Border  */
  --border-prim: 1px solid #eaecf0;
  --border-1: 0.12px solid rgba(0, 0, 0, 0.18);

  /* --Border Radious */
  --bdr-prim: 8px;

  /* -- transition property */
  --Ltrns: all 0.4s linear;
  --Etrns: all 0.5s ease;

  /* -- Fonts */
  --primary-font: "Inter", sans-serif;

  /* -- Font size */
  --inputfield-font-size: 13px;
  --hd-prime-font-s: 30px;
  --hd-sec-font-s: 25px;
  --hd-mini-font-s: 20px;

  /* -- Box Shadow */
  --shadow-A: 0px 0px 16px 0px rgba(146, 146, 146, 0.1);
  --shadow-B: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --shadow-dim: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

  /* --@Animation call */
  --aniTopBot: aniTopBot 4s infinite linear;
}

@media (max-width: 858px) {
  :root {
    /* -- Font size */
    --hd-prime-font-s: 26px;
    --hd-mini-font-s: 18px;
  }
}
@media (max-width: 600px) {
  :root {
    /* -- Font size */
    --hd-prime-font-s: 24px;
  }
}

/* ==> @keyframes */
@keyframes aniTopBot {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(4px);
  }

  100% {
    transform: translateY(0);
  }
}

* {
  font-family: var(--primary-font);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  scroll-padding: var(--scroll-padding, 5rem);
}

body {
  overflow-x: hidden;
  font-size: 14px;
  color: var(--txt-prime);
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--HD-Dark);
  font-style: normal;
  font-weight: 600;
}

p {
  color: var(--txt-prime);
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 12px;
}

a {
  text-decoration: none;
  color: var(--txt-prime);
}

img {
  width: 100%;
}

button {
  cursor: pointer;
  border: none;
}

/*------------+++++ MARK COLOR */
::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--primary);
}

::selection {
  color: white;
  background: var(--primary);
}

/*--------+++ CUSTOM SCROLLBER */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

/*=====> HELPER CLASSES  */
/* Containers */
.container-fluid {
  width: 100%;
  padding: 0 24px;
}

.container {
  width: 90%;
  margin: auto;
}

/* Tag Flex */
.tag-flex {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

/* Grid BOX */
.g-row-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.grid-row-auto-A {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 28px;
}

@media (max-width: 785px) {
  .grid-row-auto-A {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 550px) {
  .g-row-2.g-row-2-sm1 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* Section Spacing  */
.spc-y {
  --spacing-y: 80px;
  padding-top: var(--spacing-y);
  padding-bottom: var(--spacing-y);
}

.spc-y-2 {
  --spacing-y: 40px;
  padding-top: var(--spacing-y);
  padding-bottom: var(--spacing-y);
}

/* Flex properties */
.d-flex {
  display: flex;
}

.gap-15 {
  gap: 15px;
}

.align-t {
  align-items: flex-start;
}
.align-c {
  align-items: center;
}
.align-b {
  align-items: flex-end;
}

.justify-spc-b {
  justify-content: space-between;
}
.justify-end {
  justify-content: flex-end;
}
.justify-start {
  justify-content: flex-start;
}

.shrink-0 {
  flex-shrink: 0;
}

.grow-1 {
  flex-grow: 1;
}

/* Padding / Margin  */
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pe-5 {
  padding-right: 5rem;
}
.pe-10 {
  padding-right: 10px;
}
.pe-20 {
  padding-right: 20px;
}
.ps-5 {
  padding-left: 5px;
}
.ps-10 {
  padding-left: 10px;
}
.ps-20 {
  padding-left: 20px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-20 {
  padding-bottom: 20px;
}

/* Margin */
.m-0 {
  margin: 0;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.m-auto {
  margin: auto;
}
.mr-auto {
  margin-right: auto;
}
.ml-auto {
  margin-left: auto;
}

/* Others */
.d-none {
  display: none !important;
}

.img-circle {
  width: 90px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
}
.img-circle-50 {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  object-fit: cover;
}

.w-100 {
  width: 100%;
}

/* Flex Side bar & main column devider */
.flex-side-2 {
  display: flex;
  gap: 25px;
}
.flex-side-2 .flx-main-box {
  flex-grow: 1;
}
.flex-side-2 .flx-side-box {
  flex-shrink: 0;
  width: 330px;
}

/* Prime Card CSS */
.card-prim {
  border-radius: var(--bdr-prim);
  padding: 25px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

/* Table */
table {
  width: 100%;
  vertical-align: middle;
  border-spacing: 0;
}

.responsive-table {
  overflow-x: auto;
}

.responsive-table::-webkit-scrollbar {
  height: 5px;
  border-radius: 50px;
  background-color: rgb(246, 245, 245);
  overflow: hidden;
}

/* Handle */
.responsive-table::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgb(221, 221, 221);
}

@media (max-width: 1200px) {
  .container {
    width: 95%;
    max-width: 100%;
  }
}

@media (max-width: 858px) {
  p,
  a {
    font-size: 13px;
  }

  .flex-side-2 .flx-side-box {
    width: 255px;
  }
}

@media (max-width: 700px) {
  .img-circle {
    width: 80px;
  }

  .flex-side-2 {
    flex-direction: column;
  }

  .flex-side-2 .flx-side-box {
    width: 100%;
  }
}

@media (max-width: 600px) {
  p,
  a {
    font-size: 12px;
  }

  .container {
    width: 100%;
  }
}
