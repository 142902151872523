/*******************************
         HERO SEC 
********************************/
.h-hero-sec {
  position: relative;
  padding-top: 30px !important;
  padding-bottom: 65px !important;
}

/* line pattern imgs */
.h-hero-sec img {
  position: absolute;
  width: 13%;
  height: auto;
}
.h-hero-sec .img-L {
  bottom: 0;
  left: 0;
}
.h-hero-sec .img-R {
  top: 0;
  right: 0;
}

.h-hero-sec .container {
  border-radius: 24px;
  background: linear-gradient(256deg, #1d2939 23.21%, #344054 85.52%);
  padding: 90px 24px 30px 24px;
  min-height: 80vh;
}

.h-hero-sec .container .home-box {
  width: 57%;
  margin: auto;
}

.h-hero-sec .home-box .main-HD {
  text-align: center;
  font-size: 80px;
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
}
.h-hero-sec .home-box .main-HD span {
  color: var(--secoundry);
}

.h-hero-sec .home-box p {
  color: var(--bg-prime);
  text-align: center;
  margin-bottom: 35px;
  font-size: 19px;
}

.h-hero-sec .home-box .flex-btn-box {
  display: flex;
  justify-content: center;
  gap: 12px;
}

@media (max-width: 1250px) {
  .h-hero-sec .container .home-box {
    width: 65%;
  }

  .h-hero-sec .home-box .main-HD {
    font-size: 70px;
  }

  .h-hero-sec .home-box p {
    font-size: 17px;
  }
}

@media (max-width: 900px) {
  .h-hero-sec .home-box .main-HD {
    font-size: 55px;
  }

  .h-hero-sec .home-box p {
    font-size: 16px;
  }
}

@media (max-width: 735px) {
  .h-hero-sec img {
    width: 20%;
  }

  .h-hero-sec .home-box .main-HD {
    font-size: 48px;
  }

  .h-hero-sec .container .home-box {
    width: 78%;
  }

  .h-hero-sec .home-box p {
    font-size: 14px;
  }
}

@media (max-width: 550px) {
  .h-hero-sec {
    padding-bottom: 45px;
  }

  .h-hero-sec .home-box .main-HD {
    font-size: 42px;
  }

  .h-hero-sec .container .home-box {
    width: 90%;
  }
  .h-hero-sec .home-box p {
    font-size: 13px;
  }

  .h-hero-sec .home-box .flex-btn-box {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 440px) {
  .h-hero-sec .home-box .main-HD {
    font-size: 34px;
  }
}

@media (max-width: 375px) {
  .h-hero-sec .home-box .main-HD {
    font-size: 27px;
  }

  .h-hero-sec .container {
    min-height: 75vh;
  }
}

/*******************************
         xxx 
********************************/
