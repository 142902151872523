/*******************************
      HEADINGS  
  ********************************/
/* Main Heading  */
.hd-prim {
  font-size: var(--hd-prime-font-s);
  color: var(--HD-Dark);
  margin-bottom: 0;
  font-weight: 600;
  text-transform: capitalize;
}

/* Sub Heading  */
.hd-sec {
  text-transform: capitalize;
  font-size: var(--hd-sec-font-s);
  margin-bottom: 0;
  color: var(--HD-Dark);
}

/* Sub Heading  */
.hd-mini {
  text-transform: capitalize;
  font-size: var(--hd-mini-font-s);
  margin-bottom: 0;
  color: var(--HD-Dark);
}

/*******************************
               BUTTON  
  ********************************/
/* ===> 🥗 Size Varients of Btn 🥗 +++ */
/* Large Size Btn (b-lg) */
.b-lg {
  padding: 12px 25px;
  font-weight: 400;
  font-size: 14px;
  border-radius: var(--bdr-prim);
  text-transform: capitalize;
}
.btn-min-w {
  min-width: 190px;
}

/* ===> 🥗 Btn varient by color 🥗 +++++++++ */
/* Primary Color  */
.prim-btn {
  background: var(--primary);
  border: 2px solid var(--primary);
  color: white;
  transition: var(--Etrns);
}
.prim-btn:is(:hover, :active) {
  transform: translateY(-3px);
  box-shadow: var(--shadow-B);
  background: var(--primary-hover);
}

/* Primary lite Color  */
.prim-lite {
  background: var(--bg-prime-lite);
  border: 2px solid var(--bg-prime-lite);
  color: var(--txt-prime);
  transition: var(--Etrns);
}
.prim-lite:is(:hover, :active) {
  background: #defceb;
  border: 1px solid var(--secoundry);
}

/* Dime Style */
.dim-btn {
  background-color: var(--dim-color);
  border: 2px solid var(--dim-color);
  color: #393939;
  transition: var(--Etrns);
}
.dim-btn:is(:hover, :active) {
  transform: translateY(-3px);
  box-shadow: var(--shadow-B);
}

/* Primary outline btn */
.prim-outline {
  background-color: transparent;
  color: var(--primary);
  border: 2px solid var(--primary);
  font-weight: 500;
  transition: var(--Etrns);
}
.prim-outline:is(:hover, :active) {
  border: 2px solid var(--primary-hover);
  color: var(--primary-hover);
  box-shadow: var(--shadow-B);
  transform: translateY(-3px);
}

/* Dim Outline Btn */
.dim-outline {
  background-color: transparent;
  color: #344054;
  border: 2px solid #d0d5dd;
  transition: var(--Etrns);
}
.dim-outline:is(:hover, :active) {
  border: 2px solid #344054;
}

/* ---- Share btn */
.share-btn {
  width: 26px;
  height: 26px;
  padding: 0;
  display: grid;
  place-items: center;
  background-color: #eaecf0;
  color: var(--txt-prime);
  border-radius: 100%;
  font-size: 15px;
  transition: var(--Etrns);
}
.share-btn:is(:hover, :active) {
  box-shadow: var(--shadow-B);
  transform: scale(1.1);
}

/* ---- Close Btn */
.close-btn {
  width: 25px;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
  border-radius: var(--bdr-prim);
  color: var(--txt-gray);
  background-color: transparent;
  transition: var(--Etrns);
}
.close-btn:is(:active, :hover) {
  color: red;
}

/* ----- Squar btn */
.sqr-btn {
  height: 40px;
  aspect-ratio: 1/1;
  background: #f9fafb;
  border-radius: var(--bdr-prim);
  font-size: 12px;
  font-weight: 500;
  color: var(--txt-prime);
  flex-shrink: 0;
  transition: var(--Etrns);
}
.sqr-btn:is(:active, :hover) {
  box-shadow: var(--shadow-B);
  transform: translateY(-3px);
}

/* ----- White btn */
.white-btn {
  background: #f6f6f6;
  color: var(--txt-prime);
  border-radius: var(--bdr-prim);
  font-size: 11px;
  font-weight: 600;
  padding: 9px 14px;
  flex-shrink: 0;
  transition: var(--Etrns);
}
.white-btn:is(:active, :hover) {
  box-shadow: var(--shadow-dim);
  transform: translateY(-3px);
}

/* ---- Link Btn */
/* --Note:- Use .b-lg class with this btn class */
.link-btn {
  background: transparent;
  color: var(--primary);
  flex-shrink: 0;
  text-decoration: underline;
  flex-shrink: 0;
  font-weight: 600;
}
.link-btn:is(:active, :hover) {
  color: var(--primary-hover);
}

@media (max-width: 800px) {
  .b-lg {
    padding: 8px 22px;
    font-size: 12px;
  }

  .share-btn {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }

  .btn-min-w {
    min-width: 150px;
  }

  .white-btn {
    padding: 8px 13px;
  }
}

/*******************************
               SOCIAL  
  ********************************/
.social-box {
  display: flex;
  gap: 20px;
}
.social-box .s-link {
  color: var(--txt-gray);
  font-size: 18px;
  transition: var(--Ltrns);
}
.social-box .s-link:hover {
  color: var(--txt-prime);
  transform: translateY(-3px) scale(1.1);
}

@media (max-width: 800px) {
  .social-box .s-link {
    font-size: 16px;
  }
}

/*******************************
      Input / Textarea fields  
********************************/
input,
textarea {
  padding: 11px 14px;
  border-radius: var(--bdr-prim);
  box-shadow: none;
  outline: none;
  font-size: var(--inputfield-font-size);
  font-weight: 400;
}

input:where(:focus, :active, :focus-visible),
textarea:where(:focus, :active, :focus-visible) {
  box-shadow: none;
  outline: none;
}

/* Inp outline Style */
.inp-outline {
  border: 2px solid #d0d5dd;
  background-color: transparent;
  color: var(--txt-prime);
}

/* Inp Fill Outline */
.inp-fill {
  border: 2px solid var(--bg-lite);
  background-color: var(--bg-lite);
  color: var(--txt-prime);
}

/*******************************
    Form Field with icon box  
********************************/
.form-i-box {
  position: relative;
  width: 100%;
}
.form-i-box i {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  font-size: var(--inputfield-font-size);
}
.form-i-box input {
  padding-left: 32px;
  width: 100%;
}

/*******************************
         TABPANNEL 1
********************************/
.tabpannel-nav {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: flex-end;
}

.tabpannel-nav .tab-ul {
  display: flex;
  align-items: flex-end;
  gap: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.tabpannel-nav .tab-ul .tab-link {
  color: var(--txt-gray);
  font-weight: 400;
  font-size: 25px;
  text-transform: capitalize;
  display: inline-block;
  transition: var(--Ltrns);
}
.tabpannel-nav .tab-ul .tab-link:hover {
  color: var(--txt-prime);
  transform: scale(1.1);
}
.tabpannel-nav .tab-ul .tab-link.active {
  font-size: var(--hd-prime-font-s);
  color: var(--HD-Dark);
  font-weight: 600;
  cursor: default;
}
.tabpannel-nav .tab-ul .tab-link.active:hover {
  color: var(--HD-Dark);
  transform: scale(1);
  box-shadow: none;
}

/*===> TAB Indicator CSS */
.tabpannel-nav .tab-indicator-box {
  margin-bottom: 12px;
  flex-shrink: 0;
  width: 230px;
  background-color: #f2f3f6;
  height: 8px;
  border-radius: var(--bdr-prim);
  overflow: hidden;
  position: relative;
}

.tabpannel-nav .tab-indicator-box span {
  --dy-w: calc(100% / 3);
  display: inline-block;
  background-color: var(--primary);
  border-radius: var(--bdr-prim);
  position: absolute;
  width: var(--dy-w);
  height: 100%;
  top: 0;
  left: 0;
  transition: var(--Ltrns);
}

@media (max-width: 985px) {
  .tabpannel-nav .tab-ul {
    gap: 35px;
  }
  .tabpannel-nav .tab-ul .tab-link {
    font-size: 18px;
  }
}

@media (max-width: 850px) {
  .tabpannel-nav .tab-indicator-box {
    padding-bottom: 0;
    width: 160px;
    height: 5px;
  }
}

@media (max-width: 680px) {
  .tabpannel-nav {
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .tabpannel-nav .tab-ul {
    flex-wrap: wrap;
    justify-content: center;
  }

  .tabpannel-nav .tab-ul .tab-link {
    font-size: 18px;
  }
}

/*******************************
    TABPANNEL 2 (.tab-style2)
********************************/
.tabpannel-nav.tab-style2 {
  display: block;
}

.tabpannel-nav.tab-style2 .tab-ul {
  width: fit-content;
  height: fit-content;
  align-items: center;
  gap: 0;
  border-radius: var(--bdr-prim);
  overflow: hidden;
  flex-wrap: wrap;
  background-color: var(--primary-lite);
  justify-content: flex-start;
}

.tabpannel-nav.tab-style2 .tab-ul .tab-link {
  color: rgba(18, 183, 106, 0.5);
  font-weight: 400;
  font-size: 14px;
  padding: 14px 20px;
}
.tabpannel-nav.tab-style2 .tab-ul .tab-link:hover {
  color: rgba(18, 183, 106, 0.733);
}
.tabpannel-nav.tab-style2 .tab-ul .tab-link.active {
  color: var(--primary);
  font-weight: 500;
}

@media (max-width: 1100px) {
  .tabpannel-nav.tab-style2 .tab-ul .tab-link {
    font-size: 13px;
    padding: 12px 17px;
  }
}

/*******************************
      Mini Tags  
********************************/
.m-tag-sec {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.m-tag-sec .m-tag {
  display: inline-block;
  flex-shrink: 0;
  padding: 5px 8px;
  color: var(--txt-prime);
  font-size: 10px;
  font-weight: 400;
  border-radius: 5px;
}

/* Tag colors  */
.tag-pink {
  background: var(--tag-pink);
}
.tag-green {
  background: var(--tag-green);
}
.tag-purple {
  background: var(--tag-purple);
}
.tag-cream {
  background: var(--tag-cream);
}

/*******************************
      Card Components  
********************************/
/*==> CARD Elements*/
.card-ele .Category {
  font-size: 12px;
  color: var(--txt-prime);
  margin-bottom: 6px;
  padding-top: 5px;
  text-transform: capitalize;
}

.card-ele .title {
  margin-bottom: 6px;
}

.card-ele p {
  font-size: 13px;
}

.card-ele .m-tag-sec {
  margin-top: 16px;
}

/* PROJECT CARD */
.proj-card {
  --dynamic-p: 23px;
  box-shadow: var(--shadow-A);
  padding: var(--dynamic-p);
  border-radius: var(--bdr-prim);
  border: var(--border-prim);
  transition: var(--Etrns);
}
.proj-card:hover {
  animation: var(--aniTopBot);
}

.proj-card p {
  margin-top: 23px;
  margin-bottom: 28px;
}

/* ARTICLE CARD */
.article-card {
  padding-bottom: 15px;
  transition: var(--Etrns);
}
.article-card:hover {
  animation: var(--aniTopBot);
}
.article-card .article-top {
  padding-top: 22px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.article-card .article-top .title {
  padding: 0;
}
.article-card .article-top img {
  width: 20px;
}
.article-card p {
  margin-top: 15px;
  margin-bottom: 22px;
}

@media (max-width: 858px) {
  .card-comp p {
    font-size: 12px;
  }
}

/*******************************
      GIT BOX  
********************************/
.git-box {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  border: var(--border-1);
  font-size: 11px;
  color: var(--HD-lite);
}
.git-box i {
  padding-right: 4px;
}
.git-box i:nth-child(2) {
  font-size: 9px;
  color: #bfdbba;
}
.git-box span {
  color: #5c9d5b;
  font-weight: 500;
}

/*******************************
      AUTHOR BOX  
********************************/
.author-box {
  display: flex;
  align-items: center;
  gap: 15px;
}
.author-box img {
  width: 45px;
  aspect-ratio: 1/1;
  border-radius: 100%;
}
.author-box .auther-name {
  color: var(--HD-Dark);
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}
.author-box .post-date {
  color: var(--txt-prime);
  font-size: 13px;
  font-weight: 500;
}

/*******************************
Breadcrumb  
********************************/
.Breadcrumb {
  padding: 45px 0;
}
.Breadcrumb .hd-prim {
  margin-bottom: 20px;
  text-align: center;
}
.Breadcrumb p {
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

/*******************************
      PAGINATION BOX  
********************************/
.pagination-sec {
  display: flex;
  gap: 75px;
  align-items: center;
  padding-top: 20px;
  margin-top: 30px;
  border-top: var(--border-prim);
  --ratio-px: 35px;
}
.pagination-sec .pg-number-box {
  display: flex;
  flex-wrap: wrap;
}

/* ---- Pagination Buttons */
/* pg-Number */
.pagination-sec .pg-no-btn {
  width: var(--ratio-px);
  height: var(--ratio-px);
  display: grid;
  place-items: center;
  font-size: 12px;
  color: var(--txt-prime);
  font-weight: 500;
  background-color: transparent;
  border-radius: var(--bdr-prim);
  flex-shrink: 0;
  transition: var(--Etrns);
}
.pg-no-btn:hover {
  background-color: rgba(245, 245, 245, 0.286);
}
.pg-no-btn.active {
  color: #1d2939;
  background: #f9fafb;
}

/* Next / Previous btn */
.pagination-sec .pg-np-btn {
  height: var(--ratio-px);
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  color: var(--txt-prime);
  font-weight: 500;
  background-color: transparent;
  border-radius: var(--bdr-prim);
  flex-shrink: 0;
}

@media (max-width: 700px) {
  .pagination-sec {
    gap: 55px;
  }
}

@media (max-width: 680px) {
  .pagination-sec {
    flex-wrap: wrap;
  }
  .pagination-sec.justift-end {
    justify-content: space-between;
  }

  .pagination-sec {
    gap: 25px;
  }
}

/*******************************
      PROJECT BREADCRUMB BOX  
********************************/
.projBreadcrumb {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}

.projBreadcrumb .Block1 {
  flex-grow: 1;
}
.projBreadcrumb .Block1 .B1-a {
  display: flex;
  gap: 20px;
}
.projBreadcrumb .Block1 .B1-a img {
  max-width: 200px;
  border-radius: var(--bdr-prim);
}
.projBreadcrumb .Block1 .B1-a .hd-prim {
  margin: 5px 0 15px 0;
}

.projBreadcrumb .Block2 {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

@media (max-width: 740px) {
  .projBreadcrumb {
    display: block;
  }

  .projBreadcrumb .Block1 {
    margin-bottom: 30px;
  }

  .projBreadcrumb .Block2 {
    display: block;
  }
}

@media (max-width: 455px) {
  .projBreadcrumb .Block1 .B1-a {
    flex-direction: column;
  }

  .projBreadcrumb .Block1 .B1-a img {
    max-width: 100%;
  }
}
