.project-top-sec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 40px;
}

.project-top-sec .top-r-side {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}
.project-top-sec .top-r-side .sqr-btn {
  display: none;
}

.project-main {
  display: flex;
  gap: 40px;
}

.project-main aside {
  --side-filter-w: 280px;
  width: var(--side-filter-w);
  flex-shrink: 0;
}
.project-main aside .close-btn {
  display: none;
}

/* FILTER OPTION */
.filter-options {
  padding: 50px 0;
  border-top: var(--border-prim);
  border-bottom: var(--border-prim);
}

.filter-options ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.filter-options .nav-link {
  display: inline-block;
  padding: 12px;
  width: 100%;
  border-radius: var(--bdr-prim);
  color: var(--txt-prime);
  font-size: 13px;
  font-weight: 500;
  background-color: transparent;
}
.filter-options .nav-link.active {
  color: var(--primery);
  background-color: var(--bg-prime-lite);
}

.filter-options .sub-ul {
  padding-left: 10px;
}
.filter-options .sub-ul .nav-link {
  color: var(--txt-gray);
}

@media (max-width: 800px) {
  .project-main aside {
    --side-filter-w: 230px;
  }
}

/* #At this point the side menu become fix */
@media (max-width: 680px) {
  .project-top-sec {
    display: block;
  }

  .project-main {
    display: block;
  }

  .project-main aside {
    --side-filter-w: 270px;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: +1;
    padding: 50px 20px;
    box-shadow: var(--shadow-A);
    height: 100vh;
    overflow-y: auto;
    display: none;
  }
  .project-main aside.show {
    display: block;
  }

  .project-main aside .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
  }

  .project-top-sec .top-r-side {
    justify-content: space-between;
    margin-top: 30px;
  }

  .project-top-sec .top-r-side .sqr-btn {
    display: inline-block;
  }
}

/*******************************
      Share social link
********************************/
.project-main aside .social-box {
  margin-top: 30px;
  gap: 10px;
  flex-wrap: wrap;
}

.project-main aside .social-box .s-link {
  color: var(--txt-gray);
  font-size: 17px;
  border: 1px solid var(--HD-lite);
  border-radius: var(--bdr-prim);
  width: 35px;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  display: grid;
  place-items: center;
}

.project-main aside .social-box .s-link:first-child {
  color: #344054;
  border: 1px solid #d0d5dd;
}

@media (max-width: 800px) {
  .social-box .s-link {
    font-size: 16px;
  }
}
