/*******************************
          HELP CLASS  
********************************/
.viewbtn-cc {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*******************************
          OFFICIAL LINKS  
********************************/
.official-link-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.official-link-list li {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}
.official-link-list li:not(:last-child) {
  margin-bottom: 25px;
}

.official-link-list li img {
  flex-shrink: 0;
  width: 20px;
  margin-top: 5px;
}

.official-link-list li div {
  flex-grow: 1;
}
.official-link-list li div h4 {
  margin-bottom: 8px;
  text-transform: capitalize;
}
.official-link-list li div .link {
  display: block;
  color: var(--txt-prime);
  font-size: 11px;
  text-decoration: underline;
}
.official-link-list li div .link:not(:last-child) {
  margin-bottom: 6px;
}

/*******************************
        card-thumb-ul-side 
********************************/
.card-thumb-ul-side {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 20px;
}
.card-thumb-ul-side li {
  display: flex;
  gap: 20px;
}
.card-thumb-ul-side li:not(:last-child) {
  margin-bottom: 20px;
}

.card-thumb-ul-side img {
  width: 100px;
  height: auto;
  border-radius: var(--bdr-prim);
  flex-shrink: 0;
}

.card-thumb-ul-side h5 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: var(--HD-Dark);
  cursor: pointer;
}
.card-thumb-ul-side h5:hover {
  text-decoration: underline;
}

.card-thumb-ul-side .date {
  margin-bottom: 3px;
  color: var(--txt-prime);
  font-size: 12px;
}

@media (max-width: 1100px) {
  .card-thumb-ul-side h5 {
    font-size: 14px;
  }
}
@media (max-width: 570px) {
  .card-thumb-ul-side img {
    width: 90px;
    min-width: auto;
  }
}
@media (max-width: 415px) {
  .card-thumb-ul-side li {
    flex-direction: column;
    gap: 10px;
  }

  .card-thumb-ul-side img {
    width: 100%;
  }
}

/*******************************
    card-thumb-ul-side varient2 
********************************/
.card-thumb-ul-side.varient2 li {
  gap: 10px;
}

.card-thumb-ul-side.varient2 img {
  width: 75px;
}

.card-thumb-ul-side.varient2 h5 {
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0px;
  color: var(--txt-prime);
}
.card-thumb-ul-side.varient2 h5:hover {
  text-decoration: none;
}

.card-thumb-ul-side.varient2 .date {
  margin-top: 3px;
  color: var(--txt-prime);
  font-size: 9px;
}

@media (max-width: 1100px) {
  .card-thumb-ul-side h5 {
    font-size: 13px;
  }
}
@media (max-width: 570px) {
  .card-thumb-ul-side img {
    width: 70px;
  }
}

/* ********************************
        card-thumb-ul-side 
******************************** */
.card-top-thumb {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.card-top-thumb img {
  border-radius: var(--bdr-prim);
}
.card-top-thumb p {
  margin-top: 5px;
  margin-bottom: 0;
  text-align: center;
}

/*******************************
        pallets-sec 
********************************/
.pallets-sec {
  padding-bottom: 20px;
  margin-top: 40px;
}

/* ==>  status-s-row */
.pallets-sec .status-s-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}
.pallets-sec .status-s-row h6 {
  flex-shrink: 0;
  color: var(--txt-prime);
  margin: 0;
  font-size: 14px;
}

.pallets-sec .status-s-row .search-container {
  display: flex;
  gap: 5px;
}
.pallets-sec .status-s-row .search-container .bt-x {
  padding-left: 15px;
  padding-right: 15px;
  border-radius: var(--bdr-prim);
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 500;
}
.pallets-sec .status-s-row .search-container .bt-x img {
  width: 18px;
}
.pallets-sec .status-s-row .search-container input {
  width: 100%;
  min-width: 300px;
}
@media (max-width: 600px) {
  .pallets-sec .status-s-row .search-container input {
    min-width: 100%;
    width: 100%;
  }
}

/* ===> TABLE CSS */
.pallets-sec table {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 13px;
}
.pallets-sec table :where(th, td) {
  padding: 15px 20px;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  text-align: left;
}

/* ===> <thead> */
.pallets-sec table thead th {
  background: #fcfcfd;
  color: var(--txt-prime);
}
.pallets-sec table thead th button {
  margin-left: 3px;
  color: var(--txt-prime);
  border: none;
  background: transparent;
}

/* ===> <tbody> */
.pallets-sec table tbody td {
  color: var(--txt-prime);
  width: 17%;
}
.pallets-sec table tbody tr td:nth-child(1) {
  color: var(--HD-Dark);
  font-weight: 500;
  width: 15%;
}

@media (max-width: 1020px) {
  .pallets-sec table {
    font-size: 12px;
  }

  .pallets-sec table :where(th, td) {
    padding: 13px 17px;
  }
}

@media (max-width: 700px) {
  .pallets-sec table {
    min-width: 100%;
    width: auto;
  }
  /* ===> <tbody> */
  .pallets-sec table tbody td {
    min-width: 230px;
  }

  .pallets-sec table tbody tr td:nth-child(1) {
    min-width: 120px;
  }
}
